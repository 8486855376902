import { type UntypedFormControl, type ValidationErrors, type ValidatorFn } from '@angular/forms';
import { PhoneNumberUtil } from 'google-libphonenumber';

const phoneNumberUtil = PhoneNumberUtil.getInstance();

export function phoneValidator(): ValidatorFn {
  return (control: UntypedFormControl): ValidationErrors | null => {
    let isNumberValid = false;

    try {
      const phoneNumber = phoneNumberUtil.parseAndKeepRawInput(control.value);
      isNumberValid = phoneNumberUtil.isValidNumber(phoneNumber);
      // eslint-disable-next-line no-empty
    } catch {}

    return isNumberValid ? null : { phoneInvalid: true };
  };
}

export function extendedPhoneValidator(codeControlName, phoneControlName): ValidatorFn {
  return (form: UntypedFormControl): ValidationErrors | null => {
    const codeControl = form.get(codeControlName);
    const phoneControl = form.get(phoneControlName);

    let isNumberValid = false;

    try {
      const fullNumber = codeControl.value + phoneControl.value;

      const phoneNumber = phoneNumberUtil.parseAndKeepRawInput(fullNumber);
      isNumberValid = phoneNumberUtil.isValidNumber(phoneNumber);
      // eslint-disable-next-line no-empty
    } catch {}

    if (!isNumberValid) {
      phoneControl.setErrors({ ...phoneControl.errors, phoneInvalid: true });
    } else {
      phoneControl.setErrors(null);
    }

    return isNumberValid ? null : { phoneInvalid: true };
  };
}
